import { Button } from "@/components/Button/Button";
import Link from "next/link";
import { Fragment } from "react";
import MappedComponent from "../ComponentMap/ComponentMap";

const GridRow = ({ identifier, elements, map }) => {
  return (
    <div className="grid grid-cols-12 gap-4" id={identifier}>
      {Object.entries(elements).map(([key, value]: any) => {
        return <MappedComponent {...value} map={map} key={key} />;
      })}
    </div>
  );
};

export const WrapperRow = ({
  appearance,
  content,
  className = "",
  id = 1323,
}) => {
  const { layout } = appearance;
  const isFullWidth = layout === "layout-1";

  const { left = [], mid = [], right = [] } = content;
  const filteredContent = Object.entries(content)
    .filter(([key]) => key !== "button")
    .filter(([key]) => key !== "header");
  const headlineElement = Object.entries(content).filter(
    ([key]) => key === "header"
  );
  const buttonElement = Object.entries(content).filter(
    ([key]) => key === "button"
  )[0]?.[1];

  // Check if we have a 33% layout with left, mid, right sections
  const isThreeColumnLayout =
    left.length > 0 && right.length > 0 && mid.length >= 2;

  // For regular content, calculate columns as before
  const contentCount = Object.keys(filteredContent).length;
  const is10 = contentCount === 5 || contentCount === 10;
  const colSpan = is10 ? 10 / contentCount : 12 / contentCount;

  return (
    <div
      className={`${className} ${
        buttonElement
          ? "lg:my-8 mt-4 mb-0 pt-8 lg:pb-10 pb-0"
          : "lg:my-8 my-9 pt-8 pb-10"
      }`}
      id={`c${id}`}
    >
      {(headlineElement as any)?.[0]?.[1] && (
        <p className="font-bold text-center lg:text-6xl text-4xl lg:pt-4 lg:pb-9 pt-0 pb-8">
          {(headlineElement as any)[0][1]}
        </p>
      )}
      {buttonElement && <ButtonElement content={buttonElement} />}

      {isThreeColumnLayout ? (
        <div
          className={`${
            isFullWidth ? "" : "container"
          } grid grid-cols-12 gap-7`}
        >
          {/* Left column */}
          {left.length > 0 && (
            <div className="col-span-12 lg:col-span-4">
              {left.map((element, index) => (
                <div key={`left-${index}`} className="mb-7">
                  <MappedComponent {...element} />
                </div>
              ))}
            </div>
          )}

          {/* Middle column */}
          {mid.length > 0 && (
            <div className="col-span-12 lg:col-span-4">
              {mid.map((element, index) => (
                <div key={`mid-${index}`} className="mb-7">
                  <MappedComponent {...element} />
                </div>
              ))}
            </div>
          )}

          {/* Right column */}
          {right.length > 0 && (
            <div className="col-span-12 lg:col-span-4">
              {right.map((element, index) => (
                <div key={`right-${index}`} className="mb-7">
                  <MappedComponent {...element} />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${isFullWidth ? "" : "container"} grid ${
            is10 ? "grid-cols-10" : "grid-cols-12"
          } gap-7`}
        >
          {filteredContent.map(([key, elements]: any) => {
            return (
              <div
                key={key}
                className={`${
                  is10 ? "col-span-10" : "col-span-12"
                } lg:col-span-${colSpan}`}
              >
                {Object.entries(elements).map(([key, value]: any) => {
                  if (value.$$typeof)
                    return <Fragment key={key}>{value}</Fragment>;
                  return <MappedComponent {...value} key={key} />;
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ButtonElement = ({ content }) => {
  const { title, href, target } = content;

  if (!href || !title) return null;
  return (
    <div className="container">
      <div className="flex justify-center lg:pb-9  pb-8">
        <Link href={href} target={target} passHref>
          <Button>{title}</Button>
        </Link>
      </div>
    </div>
  );
};

export default GridRow;
