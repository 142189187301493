import Image from "../Image/Image";
import LottieAnimation from "../LottieAnimation/LottieAnimation";

const Gallery = ({ rows = [], hasBorderRadius = false }: any) => {
  return (
    <>
      {Object.values(rows).map((row: any, index: number) => {
        const columns = Object.values(row.columns);

        return (
          <div
            key={`row-${index}`}
            className={`grid grid-${Object.values(columns).length} h-full`}
          >
            {columns.map(({ publicUrl, properties }: any, index: number) => {
              /**
               * Lottie-animations are provided via JSON-url. All other files
               * are assumed to be normal images.
               */
              if (properties.extension === "json") {
                return (
                  <LottieAnimation
                    key={`lottie-${index}`}
                    animationUrl={publicUrl}
                    autoplay={properties.autoplay === 1}
                  ></LottieAnimation>
                );
              } else {
                return (
                  <Image
                    className={`${
                      hasBorderRadius
                        ? "xl:rounded-l-2xl xl:rounded-t-2xl xl:rounded-bl-2xl xl:rounded-tr-none rounded-t-2xl rounded-tl-2xl rounded-tr-2xl"
                        : ""
                    } h-full object-cover`}
                    key={`gallery-${index}`}
                    src={publicUrl}
                    alt={properties.alternative}
                    width={properties.dimensions.width}
                    height={properties.dimensions.height}
                    objectFit="cover"
                  />
                );
              }
            })}
          </div>
        );
      })}
    </>
  );
};

export const Typo3Gallery = ({ content }) => {
  const { gallery } = content;

  return <Gallery {...gallery} />;
};

export default Gallery;
