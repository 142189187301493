import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";
import Product from "../Product/Product";

const TileOverlay = ({ label }) => {
  return (
    <div className="absolute h-full w-full top-0 left-0 bg-white opacity-[0.88] z-10 flex justify-center items-center text-blue-light hover:text-blue-medium text-3xl text-xl">
      <p className="text-center">{label}</p>
      <Icon type="chevron" className="w5 h-5 ml-2" />
    </div>
  );
};

const CarGrid = ({ items, link = { text: "Alle Angebote", url: "/" } }) => {
  let carList = items;
  if (items.length < 8) {
    const [additionalItem, ...rest] = items;
    carList = [...items, additionalItem];
  }

  return (
    <div className="w-full py-7 lg:py-10 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 lg:gap-7 gap-4">
      {carList?.map((item, index) => {
        const isLast = index === carList.length - 1;
        return (
          <Link
            href={isLast ? link.url : item.link?.detail}
            key={item.internalid + index}
            className={`relative ${
              index < 2 && carList.length > 6 ? "hidden md:inline" : ""
            } ${isLast ? "overflow-hidden shadow-md" : ""}`}
          >
            <div
              className={`relative ${
                index < 2 && carList.length > 6 ? "hidden md:inline" : ""
              } ${isLast ? "overflow-hidden shadow-md" : ""}`}
            >
              {isLast ? <TileOverlay label={link.text} /> : null}
              <Product
                item={item}
                mediaSizes={{
                  "(max-width: 1024px)": "50vw",
                  "(max-width: 1280px)": "33vw",
                  "": "25vw",
                }}
                className={isLast ? "blur-sm" : ""}
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default CarGrid;
