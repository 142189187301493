//@ts-nocheck
import { Button } from "@/components/Button/Button";
import Image from "@/components/Image/Image";
import { Icon } from "@/components/Icon";
import Rhombus from "@/components/Masks/Rhombus";
import Select from "@/components/Select/Select";
import Link from "next/link";
import { useState } from "react";
import * as gtag from "@/lib/gtag";

import IndustryListStepper from "./components/IndustryListStepper";
import Subheadline from "./components/Subheadline";

const IndustryList = ({ content }) => {
  const [activeElement, setActiveElement] = useState(null);
  const [activeCommercial, setActiveCommercial] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const { data } = content;

  if (!data) return null;

  const { headline, subHeadline, textStep, industries } = data;

  return (
    <section className="container mx-auto lg:pt-16 lg:pb-8 pt-8 pb-4">
      <h2 className="lg:text-6xl text-4xl text-center">{headline}</h2>
      <h3 className="lg:text-5xl text-3xl font-normal text-center">
        {subHeadline}
      </h3>
      {step <= 2 && (
        <Subheadline
          headlines={textStep}
          activeStep={step}
          selectedTitle={selectedTitle}
        />
      )}
      {step === 3 && (
        <IndustryListStepper
          className="xl:w-[75%] "
          headlines={textStep}
          activeStep={step}
          selectedTitle={selectedTitle}
          setActiveStep={setStep}
          activeElement={activeElement}
          activeCommercial={activeCommercial}
        />
      )}
      {(!activeElement || step === 1) && (
        <div className={`grid lg:grid-cols-3 grid-cols-1   gap-7 `}>
          {industries.map((industry, index) => {
            return (
              <PictureTile
                variant="primary"
                content={industry}
                key={index}
                setStep={setStep}
                setActiveElement={setActiveElement}
              />
            );
          })}
        </div>
      )}
      {activeElement && step === 2 && (
        <div className=" flex  w-full    ">
          <PictureTile
            variant="secondary"
            content={industries.find((item) => item.uid === activeElement)}
            onClick={null}
            isClickable={false}
            className="max-w-[340px]  lg:flex hidden"
          />
          <div className="px-6 w-full lg:min-h-7 flex flex-col">
            <IndustryListStepper
              className="md:w-[80%] lg:w-full lg:float-left"
              headlines={""}
              activeStep={step}
              selectedTitle={selectedTitle}
              setActiveStep={setStep}
              activeElement={activeElement}
              activeCommercial={activeCommercial}
            />
            <p className="font-bold lg:text-3xl text-base lg:mb-8 mb-2 lg:flex hidden">
              Wählen Sie ihr Gewerbe aus
            </p>
            <PictureTile
              variant="secondary"
              content={industries.find((item) => item.uid === activeElement)}
              onClick={null}
              isClickable={false}
              className="!max-h-[240px] flex lg:hidden mb-8"
              showTextContent={false}
            />
            <Select
              onValueChange={(value) => {
                setActiveCommercial(value.models);
                setStep(3);
                setSelectedTitle(value.title);

                gtag.event({
                  event: "gewerbe_waehlen",
                  modul: "Branchenfunnel",
                  gewerbe: value.title || "Gewerbe",
                  step: 2,
                  step_name: "Gewerbe wählen",
                });
              }}
              showNativeSelectMobile={false}
              placeholder="Bitte wählen Sie"
              ariaLabel="Branche Auswählen"
              iconClass="!absolute  lg:text-blue-light !right-0 lg:!right-7 lg:ml-0 ml-4    opacity-1  "
              iconType="chevron"
              className="max-h-[45px] w-[310px] items-center flex justify-start  border-none bg-gray-100  !pr-5 md:!pr-11 !z-10 font-semibold "
            >
              {industries
                .find((item) => item.uid === activeElement)
                .commercials?.map(({ title, models }, index) => (
                  <Select.Item
                    key={`${index}-${title}`}
                    value={{ title, models }}
                    label={title}
                  >
                    {title}
                  </Select.Item>
                ))}
            </Select>
          </div>
        </div>
      )}
      {activeCommercial && step === 3 && (
        <div
          className={`grid lg:grid-cols-${activeCommercial?.length} grid-cols-2 lg:gap-14 gap-8 2xl:w-3/4 w-full mx-auto`}
        >
          {activeCommercial?.map((model, index) => {
            return <ModelTile content={model} key={index} />;
          })}
        </div>
      )}
    </section>
  );
};

const PictureTile = ({
  content,
  setActiveElement,
  setStep,
  isClickable = true,
  className = "",
  variant = "primary",
}) => {
  const handleClick = (uid) => {
    setActiveElement(uid);
    setStep(2);

    gtag.event({
      event: "branche_waehlen",
      modul: "Branchenfunnel",
      branche: content.title || "Branche",
      step: 1,
      step_name: "Branche wählen",
    });
  };
  return (
    <div
      className={`relative   ${
        isClickable ? "group cursor-pointer" : ""
      } ${className}`}
      onClick={isClickable ? () => handleClick(content.uid) : null}
    >
      <div className="absolute top-0 w-full h-17  bg-gradient-to-b from-black to-transparent z-10"></div>
      <Image
        className="w-full h-full group-hover:scale-105 transition-transform duration-500"
        src={content.image}
        alt={content.alt}
        width={variant === "primary" ? 465 : 340}
        height={variant === "primary" ? 320 : 410}
        objectFit="cover"
      />
      <h3 className="absolute lg:inset-7 inset-4 text-white z-20 lg:!text-3xl !text-2xl hyphens-auto">
        {content.title}
      </h3>
      <div className="absolute text-right w-full bottom-0 left-0 h-13 ">
        {variant === "primary" && (
          <div className="border-t-[1px] mx-7 border-gray-300 text-white  h-full ">
            <div className=" flex flex-row items-center justify-end h-full pb-4">
              Branche wählen{" "}
              <Icon type="plus" className="w-[14px] h-[14px] ml-3" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ModelTile = ({ content }) => {
  const { links, image, title, stats } = content;
  const offerLink = links?.offer?.link?.href;
  const infoLinkObj = links?.info?.link;

  const handleClick = () => {
    gtag.event({
      event: "empfehlung_waehlen",
      modul: "Branchenfunnel",
      fahrzeug_category: title || "",
      angebote: title || "",
      step: 3,
      step_name: "Unsere Empfehlung",
    });
  };

  return (
    <div
      className="flex flex-col justify-start relative w-full h-full group"
      onClick={handleClick}
    >
      <div className={` w-full  h-full  relative `}>
        <div>
          <div className="absolute inset-0 z-0">
            <Rhombus className={`h-full "!text-[#66bad6]" lg:p-4 p-2`} />
          </div>
          <div className="relative">
            <Image
              className={`group-hover:scale-105 transition-transform z-10 object-contain relative  
                 "max-w-[104px]"
                  `}
              src={image}
              alt="image"
              quality={75}
              width="325"
              height="270"
              sizes="(max-width: 767px) 50vw, (max-width: 1279px) 33vw, 25vw"
            />
          </div>
        </div>
      </div>

      <div className="w-full  h-full">
        <p className={` text-center truncate  font-bold  lg:my-6 my-4 `}>
          {title}
        </p>
        {offerLink ? (
          <Link href={offerLink} passHref legacyBehavior>
            <Button
              variant="regular"
              className={` md:w-full w-[95%] mx-auto whitespace-nowrap !p-2 lg:!p-5 lg lg:!text-base !text-xs`}
            >
              {stats?.numFound > 1 ? stats.numFound : "Alle"} Angebote anzeigen
            </Button>
          </Link>
        ) : (
          <div className="min-h-10"></div>
        )}
        {infoLinkObj ? (
          <Link href={infoLinkObj.href}>
            <span className="font-boldt text-blue-light underline text-center flex items-center justify-center my-6">
              Informationen
            </span>
          </Link>
        ) : (
          <div className="min-h-7 my-6"></div>
        )}
      </div>
    </div>
  );
};

export default IndustryList;
