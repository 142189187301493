import { useState, useRef, useEffect } from "react";
import Badge from "@/components/Badge/Badge";
import Tabs from "@/components/Tabs/Tabs";
import s from "./TabStage.module.css";
import { Slot } from "utils/create-slots";
import Slider from "@/components/Slider/Slider";
import CarGrid from "../CarGrid/CarGrid";
import { cva } from "class-variance-authority";
import { textColorVariant } from "@/lib/getTextColorClass";
import ArtDirectionImage from "@/components/Image/ArtDirectionImage";
import * as gtag from "@/lib/gtag";
import RateInfoBubble from "../RateInfoBubble/RateInfoBubble";

const TabStage = ({ content, id }) => {
  const { data } = content;
  const { settings, tabs } = data;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const validTabs = Object.entries(tabs).filter(
    ([_, tabData]: [string, any]) => {
      return (
        !tabData.errorCode &&
        Array.isArray(tabData.cars) &&
        tabData.cars.length > 0
      );
    }
  );

  const tabsArr: any[][] = validTabs;
  const [activeTab, setActiveTab] = useState(tabsArr?.[0]?.[0]);
  const [style, setStyle] = useState<"white" | "blue">(
    tabsArr?.[0]?.[1]?.settings?.textColor || "white"
  );

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play().catch((error) => {
              console.log("Autoplay failed:", error);
            });
          } else {
            video.pause();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    observer.observe(video);

    return () => {
      observer.disconnect();
    };
  }, [currentVideo]);

  const handleTabChange = (e: string) => {
    const [identifier = "", active] = tabsArr.find(
      ([identifier]) => e === identifier
    )!;
    setActiveTab(identifier);
    setStyle(active?.settings?.textColor || "white");
    setCurrentVideo(active?.settings?.video || null);
  };

  const headlineStyles = cva("", {
    variants: {
      textColor: textColorVariant,
    },
    defaultVariants: {
      textColor: "blue",
    },
  });

  if (!tabsArr || tabsArr.length === 0) return null;

  const isSingleTab = tabsArr.length === 1;
  const [singleTabIdentifier, singleTabData] = isSingleTab
    ? tabsArr[0]
    : [null, null];

  return (
    <>
      <div
        className={`${s.Stage} xl:min-h-screen lg:min-h-[65vh]  min-h-[544px] lg:items-center lg:p-0 lg:pb-[290px] pb-[100px]`}
      >
        {isSingleTab ? (
          singleTabData.settings?.video ? (
            <video
              key={singleTabData.settings.video}
              ref={videoRef}
              autoPlay
              playsInline
              muted
              loop
              className="absolute top-0 left-0 w-full h-full object-cover object-center"
            >
              <source src={singleTabData.settings.video} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <ArtDirectionImage
              sizes="100vw"
              priority={false}
              alt={singleTabData.settings?.tabText}
              image={singleTabData.settings?.image}
              imageMobile={singleTabData.settings?.imageMobile}
              imageTablet={singleTabData.settings?.imageTablet}
            />
          )
        ) : tabs[activeTab].settings?.video ? (
          <video
            key={tabs[activeTab].settings.video}
            ref={videoRef}
            autoPlay
            playsInline
            muted
            loop
            className="absolute top-0 left-0 w-full h-full object-cover object-center"
          >
            <source src={tabs[activeTab].settings.video} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <ArtDirectionImage
            sizes="100vw"
            priority={false}
            alt={tabs[activeTab].settings?.tabText}
            image={tabs[activeTab].settings?.image}
            imageMobile={tabs[activeTab].settings?.imageMobile}
            imageTablet={tabs[activeTab].settings?.imageTablet}
          />
        )}

        <div
          className={`md:container text-center h-full lg:w-full z-10 mb-auto mt-[2%]`}
        >
          <div className="mb-11">
            <p
              className={`lg:text-6xl text-4xl font-bold  ${
                settings.subheader !== "" ? "mb-0" : "mb-4"
              } ${style === "white" ? "text-white" : "text-blue-dark"}`}
            >
              {settings.header}
            </p>
            {settings.subheader !== "" && (
              <p
                className={`lg:text-4xl text-sm font-bold  lg:mb-5 mb-4 ${
                  style === "white" ? "text-white" : "text-blue-dark"
                }`}
              >
                {settings.subheader}
              </p>
            )}

            <Badge
              color="discount"
              size="big"
              className="lg:!text-6xl !text-4xl !font-bold lg:!px-8 !px-6  lg:!rounded-2xl !rounded-xl"
            >
              {settings.bottomHeadline}
            </Badge>
          </div>
        </div>
      </div>
      {/* sm:max-w-[690px] md:max-w-[818px] lg:max-w-[1074px] xl:max-w-[1330px] 2xl:max-w-[1586px] */}
      <div
        className={`tab-stage container pt-8 ${
          isSingleTab
            ? "lg:-mt-[200px] -mt-[120px]"
            : "lg:-mt-[290px] -mt-[100px]"
        } mb-7 overflow-x-hidden  `}
      >
        {isSingleTab ? (
          <CarGrid
            items={singleTabData.cars}
            link={{
              text: singleTabData?.settings?.linkText,
              url: singleTabData?.settings?.link || "/",
            }}
          />
        ) : (
          <Tabs.Root
            defaultValue={tabsArr?.[0][0]}
            value={activeTab}
            className="relative"
            onValueChange={handleTabChange}
          >
            <Tabs.List
              className="pl-4 md:pl-0 mx-[-48px] md:mx-0"
              aria-owns={tabsArr
                .map(([identifier]: any[]) => `${id}-${identifier}`)
                .join(" ")}
            >
              <div className={`w-full mx-auto relative`}>
                <Slider
                  className={`${s.TabStage} `}
                  trackClassName="overflow-hidden sm:skew-x-[-20deg] md:rounded-tl-xl md:rounded-br-xl lg:rounded-tl-[20px] lg:rounded-br-[20px] md:rounded-tr-[15px] md:rounded-bl-[15px] lg:rounded-tr-[30px] lg:rounded-bl-[30px]  drop-shadow-md"
                  options={{
                    perPage: 3,
                    perMove: 3,
                    gap: "12px",
                    pagination: false,
                    arrows: true,
                    width: "100%",
                    focus: 1,
                    updateOnMove: true,

                    breakpoints: {
                      640: {
                        perPage: 1,
                        perMove: 1,
                        autoWidth: true,
                        width: "auto",
                        padding: {
                          left: 20,
                          right: 20,
                        },
                        focus: 0,
                        trimSpace: false,
                        dragMinThreshold: 10,
                        start: 0,
                      },
                    },
                  }}
                >
                  {tabsArr
                    ?.filter((item) => item[1]?.cars?.length !== 0)
                    .map(([identifier, item]: any[], index: number) => {
                      return (
                        <Slider.Slide key={index}>
                          <Tabs.Trigger
                            id={`${id}-${identifier}`}
                            key={identifier}
                            value={identifier}
                            className={`active:text-white   active:bg-blue-light  transition-colors ${s.TabStageSlide} group `}
                            onClick={() => {
                              gtag.event({
                                event: "itemClick",
                                itemClick: "Carousel",
                                title: `${item.settings?.tabText}` || "",
                              });
                            }}
                          >
                            <TabTrigger
                              title={item.settings?.tabText}
                              price={item.stats?.stats?.preis?.min}
                              financeRate={
                                item.stats?.stats?.monatlicherateFinanzierung
                                  ?.min
                              }
                              showNettoPreis={item.settings?.showNettoPreis}
                              stats={item.stats}
                            />
                          </Tabs.Trigger>
                        </Slider.Slide>
                      );
                    })}

                  <Slot name="arrows">
                    <Slider.Arrows
                      variant="gray"
                      position="outside"
                      className="!hidden xl:!flex"
                    />
                  </Slot>
                </Slider>
              </div>
            </Tabs.List>

            {tabsArr.map(([identifier, item]: any[], index: number) => (
              <Tabs.Content key={identifier} value={identifier}>
                <CarGrid
                  items={item.cars}
                  link={{
                    text: item?.settings?.linkText,
                    url: item?.settings?.link || "/",
                  }}
                />
              </Tabs.Content>
            ))}
          </Tabs.Root>
        )}
        {settings.footerText && (
          <div
            className={`${s.TabStageFooter} mb-7 lg:mb-10 text-gray-600 text-sm text-justify`}
            dangerouslySetInnerHTML={{ __html: settings.footerText }}
          ></div>
        )}
      </div>
    </>
  );
};

const Headline = ({ children }) => {
  return (
    <p className="text-center pb-8 text-white  text-4xl font-bold">
      {children}
    </p>
  );
};

const TabTrigger = ({
  title,
  price,
  financeRate,
  showNettoPreis = true,
  stats,
}) => {
  return (
    <div className={`${s.TabStageTrigger} sm:skew-x-[20deg] relative group`}>
      <p className="lg:text-2xl text-sm font-bold group-hover:text-gray-100 md:truncate whitespace-nowrap max-w-full px-5">
        {title}
      </p>
      <div className="flex group">
        {!!price && (
          <span className="text-[13px] text-gray-600 group-hover:text-gray-100">
            ab{" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(price)}
            {showNettoPreis && " zzgl. Mwst."}
          </span>
        )}
        {!!financeRate && (
          <span className="text-[13px] text-gray-600 group-hover:text-gray-100 lg:block hidden">
            &nbsp; | ab{" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(financeRate)}
            {showNettoPreis ? " mtl. zzgl. Mwst." : " mtl."}
          </span>
        )}
        {stats?.lowestCar?.financingdata && (
          <RateInfoBubble
            financedata={stats.lowestCar.financingdata}
            showNettoPreis={showNettoPreis}
            iconVariant={"TabStage"}
          />
        )}
      </div>
    </div>
  );
};

export default Object.assign(TabStage, { Headline });
