import {
  Root,
  Item as PrimitiveItem,
  Header as PrimitiveHeader,
  Trigger as PrimitiveTrigger,
  Content as PrimitiveContent,
} from "@radix-ui/react-accordion";
import { FC, PropsWithChildren, useState, useEffect, useRef } from "react";
import { createSlots, Slot } from "../../utils/create-slots";
import MappedComponent from "../../modules/ComponentMap/ComponentMap";
import { Icon } from "../Icon";
import HTML from "@/modules/HTML/HTML";

const Accordion: FC<PropsWithChildren<any>> = ({
  children,
  type = "single",
  defaultValue = "",
  collapsible = false,
}) => {
  return (
    <Root
      type={type}
      className="@container"
      collapsible={collapsible}
      defaultValue={defaultValue}
    >
      {children}
    </Root>
  );
};

const Trigger = ({ children, ...props }) => (
  <PrimitiveTrigger
    {...props}
    className="flex items-center w-full text-base lg:text-2xl @xs:!text-base  @3xl:!text-2xl"
  >
    {children}
  </PrimitiveTrigger>
);

const Header = ({ children, className = "" }) => (
  <PrimitiveHeader className={`flex items-center w-full ${className}`} asChild>
    <div className="font-bold">
      {children}
      <PrimitiveTrigger>
        <Icon
          type="chevron"
          purposeLabel="Akkordeon öffnen"
          className="transition-transform rotate-90 group-open:-rotate-90 w-[20px] lg:w-[24px] @xs:!w-[20px]  @3xl:!w-[24px] ml-7 text-blue-light"
        />
      </PrimitiveTrigger>
    </div>
  </PrimitiveHeader>
);

const Item = ({ children, value }) => {
  const [title, header, content] = createSlots(
    ["title", "header", "content"],
    children
  );
  return (
    <PrimitiveItem
      value={value}
      className="group border-b-[1px] border-gray-300 py-6 lg:py-7 px-6 @xs:!py-6  @3xl:!py-7"
    >
      {header || <Header>{title}</Header>}
      {content}
    </PrimitiveItem>
  );
};

const Content = ({ children, className = "" }) => (
  <PrimitiveContent
    className={`pt-6 lg:pt-7 xs:!pt-6  @3xl:!pt-7 open:transition-[height] ${className}`}
  >
    {children}
  </PrimitiveContent>
);

// New component to handle excerpt and full content
const HTMLWithExcerpt = ({ content, className = "" }) => {
  const [excerpt, setExcerpt] = useState("");

  useEffect(() => {
    // This will run after the component mounts
    if (typeof window !== "undefined") {
      // Create a temporary div to parse the HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;
      const textContent = tempDiv.textContent || tempDiv.innerText || "";

      // Create excerpt (first 100 characters)
      const excerptText =
        textContent.length > 100
          ? textContent.substring(0, 160) + "..."
          : textContent;

      setExcerpt(excerptText);
    }
  }, [content]);

  return (
    <div>
      {excerpt && (
        <div className="text-gray-900 text-sm font-light mt-7 group-open:!hidden">
          {excerpt}
        </div>
      )}
      <div className="group-open:block !hidden">
        <HTML content={content} className={className} />
      </div>
    </div>
  );
};

export const Typo3Accordion = ({ content, id, appearance }) => {
  const { mid, header = "", headerPosition = "" } = content;
  const { layout = "default" } = appearance;

  if (layout === "layout-1") {
    return (
      <div className="container [&>div>div>div]:!items-start [&>div>div>div]:!mt-5">
        {header && (
          <Headline
            label={header}
            position={headerPosition}
            scrollAnchor={id}
          />
        )}

        <Accordion type="single" collapsible className="group">
          {mid.map((component, index) => {
            // Create a copy of the component with header overridden
            const modifiedComponent = {
              ...component,
              content: {
                ...component.content,
                header: "",
              },
            };

            return (
              <Item value={`${index + index}`} key={`${index + index}`}>
                <Slot name="title">
                  <Trigger>
                    <div className="flex flex-col justify-start items-start text-left">
                      <p className="text-blue-dark text-2xl block w-full">
                        {component.content.header}
                      </p>
                      <div>
                        <HTMLWithExcerpt content={component.content.bodytext} />
                      </div>
                    </div>
                  </Trigger>
                </Slot>
                <Slot name="content">
                  <Content className="[&>*]:text-gray-900 [&>*]:text-sm [&>*]:font-light ">
                    <MappedComponent {...modifiedComponent} />
                  </Content>
                </Slot>
              </Item>
            );
          })}
        </Accordion>
      </div>
    );
  } else {
    return (
      <div className="container">
        {header && (
          <Headline
            label={header}
            position={headerPosition}
            scrollAnchor={id}
          />
        )}
        <Accordion type="single" collapsible>
          {mid.map((component, index) => {
            return (
              <Item value={`${index + index}`} key={`${index + index}`}>
                <Slot name="title">
                  <Trigger>{component.content.header}</Trigger>
                </Slot>
                <Slot name="content">
                  <Content>
                    <MappedComponent {...component} />
                  </Content>
                </Slot>
              </Item>
            );
          })}
        </Accordion>
      </div>
    );
  }
};

const Headline = ({ label, position, scrollAnchor = "" }) => {
  const getPosition = (position) => {
    // 0:zentriert | 1:link | 2:rechts
    switch (position) {
      case "0":
        return "text-center";
      case "1":
        return "text-left";
      case "2":
        return "text-right";

      default:
        return "text-left";
    }
  };
  return (
    <h2
      id={`c${scrollAnchor}`}
      className={`border-b-[1px] border-gray-300 font-bold text-blue-dark lg:text-5xl text-4xl lg:pb-9 pb-7 lg:pt-7 pt-6  ${getPosition(
        position
      )}`}
    >
      {label}
    </h2>
  );
};

export type Typo3AccordionProps = {
  type: "accordion";
  content: {
    mid: any[];
  };
};

export default Object.assign(Accordion, {
  Trigger,
  Content,
  Item,
  Header,
  Headline,
});
