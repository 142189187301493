import { Button } from "@/components/Button/Button";
import Gallery from "@/components/Gallery/Gallery";
import { FC } from "react";
import Text from "../Text/Text";
import { getColPosContainer } from "utils/get-col-pos-container";

interface PositionI {
  horizontal: "left" | "right" | "center";
}
interface GalleryI {
  position: PositionI;
}
interface ContentI {
  gallery: GalleryI;
  bodytext: string;
  header: string;
  subheader: string;
  headerLink: HeaderLinkI;
}

interface HeaderLinkI {
  href: string;
  target?: string;
  class?: string;
  title: string;
  linkText?: string;
  additionalAttributes?: any[];
}

const TextImage: FC<{
  content: ContentI;
  id: string;
  appearance?: any;
  colPos?: number;
  parentBackgroundColor?: string;
}> = ({ content, id, appearance, colPos, parentBackgroundColor }) => {
  const { gallery, headerLink } = content;
  const { position } = gallery;
  const { layout = "default" } = appearance;
  const isBlackBackground = parentBackgroundColor === "7";

  const isLeft = position?.horizontal === "left";
  const isRight = position?.horizontal === "right";
  const isAbove = position?.horizontal === "center";

  switch (layout) {
    case "layout-1":
      return (
        <div className="@container" id={`c${id}`}>
          <div
            className={`${getColPosContainer(
              colPos
            )} w-full grid grid-cols-1 relative xl:grid-cols-2  h-full`}
          >
            <div className="h-full order-1 md:order-none @3xl:!order-none">
              <ImageCol gallery={gallery} hasBorderRadius={true} />
            </div>
            <div
              className={`${
                parentBackgroundColor === "7" ? "bg-gray-600" : "bg-gray-100"
              } xl:px-12 xl:py-15 lg:px-8 lg:py-10 px-6 py-7  flex flex-col justify-center h-full order-2 md:order-none @3xl:!order-none xl:rounded-tr-2xl xl:rounded-bl-none rounded-br-2xl rounded-bl-2xl`}
            >
              <Text
                content={content}
                noPadding={true}
                parentBackgroundColor={parentBackgroundColor}
              />
              <OptionalButton
                {...headerLink}
                isBlackBackground={isBlackBackground}
              />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="@container" id={`c${id}`}>
          <div
            className={`${getColPosContainer(
              colPos
            )} w-full grid grid-cols-1  relative ${
              isAbove ? "" : "md:grid-cols-2 @3xl:!grid-cols-2"
            }`}
          >
            {isAbove && <ImageCol gallery={gallery} />}
            {isLeft && <ImageCol gallery={gallery} />}
            <div
              className={`${
                isLeft ? "@3xl:!pl-16 md:pl-16" : "md:pr-16 @3xl:!pr-16"
              } py-6 flex flex-col justify-center h-full order-2 md:order-none @3xl:!order-none `}
            >
              <Text
                content={content}
                noPadding={true}
                parentBackgroundColor={parentBackgroundColor}
              />
              <OptionalButton
                {...headerLink}
                isBlackBackground={isBlackBackground}
              />
            </div>
            {isRight && <ImageCol gallery={gallery} />}
          </div>
        </div>
      );
  }
};

const ImageCol = ({ gallery, hasBorderRadius = false }) => (
  <div className="flex flex-col justify-center h-full w-full">
    <Gallery {...gallery} hasBorderRadius={hasBorderRadius} />
  </div>
);
const OptionalButton = ({ href, title, isBlackBackground }) => {
  return (
    <>
      {href && title && (
        <div className="lg:container flex justify-start items-center lg:mt-9 mt-7 lg:mb-0 mb-6">
          <Button
            as="a"
            href={href}
            className={`lg:w-auto w-full ${
              isBlackBackground ? "text-white" : ""
            }`}
          >
            {title}
          </Button>
        </div>
      )}
    </>
  );
};

export default TextImage;
