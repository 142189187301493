export enum BackgroundColorsEnum {
  "bg-white",
  "bg-blue-dark text-white",
  "bg-blue-light",
  "bg-orange-light",
  "bg-green-light",
  "bg-gray-100 !text-blue-dark",
  "bg-gray-300",
  "bg-black !text-white",
}
